// Generated by Framer (4ba8877)

import { addFonts, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-G14y1"

const variantClassNames = {eeFjSHYpB: "framer-v-kj889e"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "eeFjSHYpB", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} as={"header"} background={{alt: "", fit: "fill", intrinsicHeight: 3112, intrinsicWidth: 2279, loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 822, pixelWidth: 540, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/mk9SY2zmCj4ITLqwfGJsACO5c.png", srcSet: "https://framerusercontent.com/images/mk9SY2zmCj4ITLqwfGJsACO5c.png 540w"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-kj889e", className, classNames)} data-framer-name={"Estéfano"} layoutDependency={layoutDependency} layoutId={"eeFjSHYpB"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 24, borderBottomRightRadius: 24, borderTopLeftRadius: 24, borderTopRightRadius: 24, ...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-G14y1.framer-g2gulm, .framer-G14y1 .framer-g2gulm { display: block; }", ".framer-G14y1.framer-kj889e { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 4px; height: 411px; justify-content: flex-end; overflow: visible; padding: 49px; position: relative; width: 270px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-G14y1.framer-kj889e { gap: 0px; } .framer-G14y1.framer-kj889e > * { margin: 0px; margin-bottom: calc(4px / 2); margin-top: calc(4px / 2); } .framer-G14y1.framer-kj889e > :first-child { margin-top: 0px; } .framer-G14y1.framer-kj889e > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 411
 * @framerIntrinsicWidth 270
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerhalueKtu8: React.ComponentType<Props> = withCSS(Component, css, "framer-G14y1") as typeof Component;
export default FramerhalueKtu8;

FramerhalueKtu8.displayName = "Estefano sagiorato";

FramerhalueKtu8.defaultProps = {height: 411, width: 270};

addFonts(FramerhalueKtu8, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})